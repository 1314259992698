import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import logo from '../assets/logo.png';
import profile from '../assets/profile.png';
import BreadCrum from './BreadCrum';
import { useLocation } from 'react-router-dom';

const Menu = () => {
  const jwtToken = localStorage.getItem('JWToken');
  const { isLoggedIn, userRole } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div className="flex z-100">
      <nav className="flex justify-between items-center fixed top-0 left-0 right-0 z-10 bg-gray-800 p-4">
        <div className="flex items-center">
          {isLoggedIn && <Link to="/"><img src={logo} alt="Logo" className="h-12 w-12 mr-2 hidden sm:block" /></Link>}
          {!isLoggedIn && <img src={logo} alt="Logo" className="h-12 w-12 mr-2 hidden sm:block" />}
          {isLoggedIn && <Link to="/" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Accueil</Link>}
          {isLoggedIn && <Link to="/atlas" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Atlas</Link>}
          {isLoggedIn && <Link to="/crm" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">CRM</Link>}
          {isLoggedIn && <Link to="/inventory" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Inventaire</Link>}
          {isLoggedIn && <Link to="/support" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Support</Link>}
          {/*isLoggedIn && <Link to="/sms" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">SMS</Link>*/}
          {isLoggedIn && userRole === "admin" && <Link to="/admin/users" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Admin Dashboard</Link>}

        </div>
        <div className="flex items-center">
          {!isLoggedIn && <Link to="/login" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700">Se connecter</Link>}
          {isLoggedIn && <Link to="/profile" className="ml-4 flex items-center justify-center bg-gray-700 text-white rounded-full h-10 w-10 hover:bg-gray-500">
              <img src={profile} alt="Profile" className="p-1 h-full w-full" />
          </Link>}
        </div>
      </nav>
      <div className="mt-4 w-full">
      {!location.pathname.startsWith("/atlas") && !location.pathname.startsWith("/crm") && !location.pathname.startsWith("/admin") && <BreadCrum/>}
        <Outlet/>
      </div>
    </div>
  );
};

export default Menu;
