import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer = () => {

  const location = useLocation();

  if (location.pathname === '/inventory') {
    return null;
  }
  return (
    <footer className="bg-gray-800 text-white pt-6 z-50">
      <div className="max-w-6xl m-auto bg-gray-800 flex flex-wrap justify-center">
        {/* Section for tools */}
        <div className="p-5 w-48 ">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Outils
          </div>
          <Link to="/inventory" className="my-3 block">Aide à l'inventaire</Link>
          <Link to="/tickets" className="my-3 block">Support Technique</Link>
          <Link to="/watch" className="my-3 block">Veille Concurrentielle</Link>
          <Link to="/mailing" className="my-3 block">Envoi d'Emails</Link>
        </div>

        {/* Section for tutorials */}
        <div className="p-5 w-48">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Tutoriels
          </div>
          <Link to="/tutorial/inventory" className="my-3 block">Tutoriel Inventaire</Link>
          <Link to="/tutorial/tickets" className="my-3 block">Tutoriel Support</Link>
          <Link to="/tutorial/watch" className="my-3 block">Tutoriel Veille</Link>
          <Link to="/tutorial/mailing" className="my-3 block">Tutoriel Emails</Link>
        </div>

        {/* Section for legal or company information */}
        <div className="p-5 w-48">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Entreprise
          </div>
          <Link to="/about" className="my-3 block">À propos de nous</Link>
          <Link to="/privacy" className="my-3 block">Politique de confidentialité</Link>
          <Link to="/terms" className="my-3 block">Conditions d'utilisation</Link>
        </div>

        {/* Section for social or contact */}
        <div className="p-5 w-48">
          <div className="text-xs uppercase text-gray-400 font-medium mb-6">
            Contact & Réseaux
          </div>
          <Link to="/contact" className="my-3 block">Contactez-nous</Link>
          {/* Include social media links if applicable */}
        </div>
      </div>

      <div className="pt-2">
        <div className="flex pb-5 px-3 m-auto pt-5 
          border-t border-gray-500 text-gray-400 text-sm 
          flex-col md:flex-row max-w-6xl justify-center">
          <div className="mt-2">
            © {new Date().getFullYear()} Tous droits réservés.
          </div>
          {/* Add any additional legal notices or credits */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
