import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { Link } from 'react-router-dom';
import ToolCard from '../components/HomePageToolCardComponent';
import TutorialLink from '../components/HomePageTutorialLink';

import homePicture from '../assets/1home.webp';
import inventoryPicture from '../assets/2inventory.webp';
import supportPicture from '../assets/3support.webp';
import veillePicture from '../assets/4veille.webp';
import mailPicture from '../assets/5mail.webp';
import tutorialPicture from '../assets/6tutorial.webp';
import globalIntraPicture from '../assets/7globalintranet.webp';




const HomePage = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <div className="bg-gray-100 pb-10">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center py-12">Bienvenue sur votre Intranet</h1>

          {/* Hero section with background image and dark overlay */}
          <div className="relative bg-white rounded-lg shadow mb-8">
            <img src={homePicture} alt="Accueil" className="rounded-lg w-full object-cover h-96"/>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 rounded-lg"></div> {/* Overlay */}
            <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center p-6 rounded-lg">
              <h2 className="text-3xl font-semibold text-white mb-5">Commencez votre journée ici</h2>
              <p className="text-lg text-white mb-5 text-center">Accédez rapidement aux outils essentiels et consultez les dernières mises à jour internes. Restez informé, organisé et connecté à travers l'intranet.</p>
              <Link to="/atlas" className="bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 transition-colors duration-300">
                Accéder au Dashboard
              </Link>
            </div>
          </div>

          {/* Tools grid section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {/* Inventory tool card */}
            <ToolCard 
              title="Aide à l'inventaire"
              description="Outil de suivi et gestion en temps réel lors de l'inventaire."
              link="/inventory"
              buttonText="Explorer"
              imgSrc={inventoryPicture}
              imgAlt="Aide à l'inventaire"
            />

            <ToolCard 
              title="Support Technique"
              description="Gestion des tickets pour toutes vos demandes techniques."
              link="/support"
              buttonText="Support"
              imgSrc={supportPicture}
              imgAlt="Support Technique"
            />

            <ToolCard 
              title="Veille Concurrentielle"
              description="Outils d'analyse concurrentielle pour rester à la pointe du marché."
              link="/atlas"
              buttonText="Veiller"
              imgSrc={veillePicture}
              imgAlt="Veille Concurrentielle"
            />

            <ToolCard 
              title="Envoi d'Emails"
              description="Créez et gérez des campagnes d'emailing efficaces."
              //link="/mailing"
              buttonText="Commencer"
              imgSrc={mailPicture}
              imgAlt="Envoi d'Emails"
            />

          </div>

          {/* Tutorials section */}
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <h2 className="text-2xl font-semibold mb-5">Tutoriels et Guides</h2>
            <p className="mb-5">Apprenez à utiliser l'intranet avec nos guides et tutoriels détaillés pour chaque outil.</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <TutorialLink 
                title="Tutoriel Inventaire"
                //link="/tutorial/inventory"
                imgSrc={tutorialPicture}
              />

              <TutorialLink 
                title="Tutoriel Support"
                //link="/tutorial/tickets"
                imgSrc={tutorialPicture}
              />

              <TutorialLink 
                title="Tutoriel Veille"
                //link="/tutorial/watch"
                imgSrc={tutorialPicture}
              />

              <TutorialLink 
                title="Tutoriel Mailing"
                //link="/tutorial/mailing"
                imgSrc={tutorialPicture}
              />
              {/* Add more tutorial links as needed */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
