import React from 'react';
import { Link } from 'react-router-dom';

const TutorialLink = ({ title, link, imgSrc }) => {
  return (
    <div className="flex w-full p-4 text-center bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mb-6">
      <div className="w-1/3">
        <img src={imgSrc} alt={title} className="object-cover h-full w-full rounded-l-lg"/>
      </div>
      <div className="w-2/3 flex flex-col justify-center items-start pl-4">
        <h3 className="text-lg font-semibold text-left text-gray-800 mb-2">{title}</h3>
        <Link to={link} className="inline-block text-sm font-medium py-2 px-4 rounded text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition-colors duration-300">
          Voir le tutoriel
        </Link>
      </div>
    </div>
  );
};

export default TutorialLink;
