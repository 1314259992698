import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const LoadingSpinner = ({ color = "#00BFFF", height = 80, width = 80 }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-900 overflow-hidden">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
        <ThreeDots color={color} height={height} width={width} />
        <img src="https://cuisinesetfourneaux.com/wp-content/uploads/2021/09/logo-Cuisines-et-fourneaux-1.png" className="h-24 w-24 absolute -top-32" alt="Loading" />
      </div>
    </div>
  );
};

export default LoadingSpinner;
