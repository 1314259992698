// Description: Ce composant est utilisé pour afficher un fil d'ariane basé sur l'URL actuelle.
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowLeft } from 'react-icons/md';

const Breadcrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const rate = queryParams.get('rate'); // Récupération du paramètre 'rate'
  const productReference = queryParams.get('productReference'); // Récupération du paramètre 'rate'


  const pathnames = location.pathname.split('/').filter(x => x);

  const breadcrumbSegments = [
    { name: "Accueil", path: "/" },
    ...pathnames.map((value, index) => {
      const path = `/${pathnames.slice(0, index + 1).join('/')}`;
      let name = value;
      // Ajoutez vos conversions ici
      if (value === 'products') name = "Produits";
      if (value === 'support') name = "Support";
      if (value === 'atlas') name = "Atlas";
      if (value === 'crm') name = "CRM";
      if (value === 'inventory') name = "Inventaire";
      if (value === 'admin') name = "Panel Administateur";
      if (value === 'users') name = "Utilisateurs";
      if (value === 'stockerror') name = "Erreur de stock";
      if (value === 'warehouse') name = "Entrepôts";
      if (value === 'supportticket') name = "Tickets de support";
      if (value === 'overview') name = "Vue d'ensemble";
      if (value === 'total') name = "Tous les produits";
      if (value === 'matched') name = "Produits matchés";
      if (value === 'graph') name = "Graphique";
      if (value === 'last-product-move') name = "Derniers mouvements de produits";
      if (value === 'auto-login') name = "Connexion automatique";
      if (value === 'login') name = "Connexion";
      if (value === 'profile') name = "Profil";
      if (value === 'competitors') name = "Concurrents";
      if (value === 'analysis') name = "Analyse";
      if (value === 'tools') name = "Outils";
      if (value === 'calculator') name = "Calculatrice";
      if (value === 'converter') name = "Convertisseur";
      if (value === 'settings') name = "Paramètres";
      if (value === 'preferences') name = "Préférences";
      if (value === 'list') name = "Liste";
      if (value === 'daily-reports') name = "Rapports quotidiens";

      // Ajoutez des informations supplémentaires basées sur les paramètres d'URL pour le dernier segment
      if (index === pathnames.length - 1 && rate) {
        switch (rate) {
          case 'promo':
            name += " en promotions";
            break;
          case 'comp':
            name += " compétitifs";
            break;
          case 'equal':
            name += " au même prix";
            break;
          case 'non-comp':
            name += " non competitifs";
            break;
          default:
            name += ` (${rate})`;
        }
      }
      if (index === pathnames.length - 1 && productReference) {
            name += ` ${productReference}`;
      }
      return { name, path };
    })
  ];

  const goBack = () => {
    navigate(-1); // Retour à la page précédente
  };

  return (
    <div className="bg-white flex items-center w-full border-2">
      <div className="p-2 cursor-pointer" onClick={goBack}>
        <MdKeyboardArrowLeft className="text-4xl" />
      </div>
      <nav aria-label="breadcrumb" className="flex-1">
        <ol className="flex items-center text-gray-600">
          {breadcrumbSegments.map((segment, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <span className="px-2">/</span>}
              <li className={`${index === breadcrumbSegments.length - 1 ? 'font-bold' : ''}`}>
                {index === breadcrumbSegments.length - 1 ? (
                  <span>{segment.name}</span>
                ) : (
                  segment.name === 'Graphique' || segment.name === "Dernier mouvement de produit" || segment.name === "Panel Administateur" ||
                  segment.name === 'Outils' || segment.name === 'Concurrents' || segment.name === 'Paramètres' || segment.name === 'Vue d\'ensemble' ? (
                    <span>{segment.name}</span>
                  ) : (
                    <Link className="underline" to={segment.path}>{segment.name}</Link>
                  )
                )}
              </li>
            </React.Fragment>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;