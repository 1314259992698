import React from 'react';
import { Link } from 'react-router-dom';

const ToolCard = ({ title, description, link, buttonText, imgSrc, imgAlt }) => {
    return (
        <div className="bg-white rounded-lg shadow p-4 flex flex-col hover:shadow-md transition-shadow duration-300">
        <div className="mb-3 w-full h-40">
            <img src={imgSrc} alt={imgAlt} className="w-full h-full object-cover rounded-lg" />
        </div>
        <h3 className="font-semibold text-lg mb-2">{title}</h3>
        <p className="text-sm text-gray-600 mb-4">{description}</p>
        <Link to={link} className="mt-auto bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors duration-300 text-center">
            {buttonText}
        </Link>
        </div>
    );
};

export default ToolCard;
